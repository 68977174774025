import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import Icon from './Icon'

export default function F() {
    return (
        <View style={styles.container}>
            <View style={{width: 16}}/>
            <Icon width={32} height={32} />
            <View style={{width: 16}}/>
            <Text style={styles.text}>
                LO-CO
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    text: {
        fontWeight: 'bold',
        fontSize: 26,
        color: 'white',
    }
})
