import React, {useEffect, useState} from 'react'

export default function F({url}) {
    // Assumes the json contains just a string.
    
    if (!url.endsWith(".json")) {
        const s = 'FetchJsonString expected URL to end with `.json`. url = '+url
        console.warn(s)
        return null
    }

    const [str, setStr] = useState(null)

    useEffect(() => {
        let didCancel = false
        async function get() {
            try {
                const response = await fetch(url)
                const data = await response.json()
                if (!didCancel) {
                    setStr(data)
                }
            } catch (e) {
                const s = 'FetchJsonString failed, error: ' + e.message
                console.warn(s)
            }
        }
        get()
        return () => {didCancel = true}
    } , [])

    return str
}
