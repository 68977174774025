import React from 'react'
import { StyleSheet, View, Text, Image } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { Linking } from 'expo'
import TermsString from './myComponents/TermsString'
import PrivacyString from './myComponents/PrivacyString'
import Title from './myComponents/Title'
import Phone from './myComponents/Phone'
import { TouchableOpacity } from 'react-native-gesture-handler'

// const playStoreLink = 'http://play.google.com/store/apps/details?id=loyalcoffeeltd.loco'
// const playStoreLink = 'market://details?id=loyalcoffeeltd.loco'
const playStoreLink = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=loyalcoffeeltd.loco'
const appStoreLink = 'https://apps.apple.com/gb/app/lo-co-digital-loyalty-cards/id1526183991?mt=8'

const navRef = React.createRef()
const Stack = createStackNavigator()
const prefix = Linking.makeUrl('/')
const linkingConfig = {
  screens: {
    Home: '',
    Privacy: 'privacy',
    Terms: 'terms',
    Support: 'support',
    AppStoresRedirect: 'apps',
    NotFound: '*'
  }
}
const linking = {
  prefixes: [prefix],
  config: linkingConfig,
}

export default function App() {
  return (
    <NavigationContainer ref={navRef} linking={linking} fallback={<Text>Loading...</Text>}>
      <Stack.Navigator
        initialRouteName="Home"
        screenOptions={{
          headerStyle: styles.navHeader,
          headerTitleStyle: styles.navTitle,
          headerLeft: () => <TitleClickable />,
          headerTitleAlign: 'center',
        }}
      >
        <Stack.Screen name="Home" component={HomeScreen} options={{ headerLeft: () => <Title />, headerTitle: '' }} />
        <Stack.Screen name="Privacy" component={PrivacyScreen} options={{ headerTitle: 'Privacy Policy' }} />
        <Stack.Screen name="Terms" component={TermsScreen} options={{ headerTitle: 'Terms and Conditions' }} />
        <Stack.Screen name="Support" component={SupportScreen} options={{ headerTitle: 'Support' }} />
        <Stack.Screen name="AppStoresRedirect" component={AppStoresRedirect} options={{ headerShown: false }} />
        <Stack.Screen name="NotFound" component={NotFound} />
      </Stack.Navigator>
    </NavigationContainer>
  )
}

function TitleClickable() {
  return (
    <TouchableOpacity onPress={() => navRef.current?.navigate('Home')}>
      <Title />
    </TouchableOpacity>
  )
}

function HomeScreen() {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        Digital loyalty cards for independent coffee shops.
      </Text>

      <Phone width={300} />

      <Text style={styles.text}>
        Coming soon.
      </Text>
    </View>
  )
}

function PrivacyScreen() {
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={styles.text}>
          <PrivacyString />
        </Text>
      </View>
    </View>
  )
}

function TermsScreen() {
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={styles.text}>
          <TermsString />
        </Text>
      </View>
    </View>
  )
}

function SupportScreen() {
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <Text style={styles.text}>
          {'For technical support, please contact: sid@loyalcoffee.io'}
        </Text>
      </View>
    </View>
  )
}

function NotFound() {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>Page not found.</Text>
    </View>
  )
}

function AppStoresRedirect() {
  // Redirect to app stores, based on user agent.
  // https://stackoverflow.com/a/15710945
  const [alreadySent, setAlreadySent] = React.useState(false)
  const userAgent = navigator.userAgent.toLowerCase()
  const android = userAgent.includes('android')
  const apple = userAgent.includes('ipod') || userAgent.includes('iphone') || userAgent.includes('ipad')

  if (android) {
    // Linking.openURL(playStoreLink)
    window.open(playStoreLink)
    // Linking.openURL('/')
    // navRef.current?.navigate('Home', {'redirectCallback': () => window.open(playStoreLink)})
    return 'Redirecting to Google Play Store... '
  }
  else if (apple) {
    Linking.openURL(appStoreLink)
    // setTimeout(() => Linking.openURL('/'), 500)
    return 'Redirecting to App Store...'
  }
  else {
    Linking.openURL('/')
    return 'Redirecting to homepage.'
  }
}

const colors = {
  brown: '#674147',
  pink: '#e7b7b5',
  white: '#fff'
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: colors.brown
  },
  textContainer: {
    maxWidth: 666,
    borderWidth: 33,
    borderColor: 'rgba(0, 0, 0, 0)',
  },
  text: {
    color: colors.white,
    fontSize: 16,
  },
  navHeader: {
    backgroundColor: colors.brown,
    borderWidth: 2,
    borderColor: 'white',
    borderBottomWidth: 2,
    borderBottomColor: 'white',
    shadowColor: 'transparent',
    shadowOpacity: 0,
    elevation: 0,
  },
  navTitle: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 16,
  }
});



// https://reactnavigation.org/docs/configuring-links
// https://reactnavigation.org/docs/hello-react-navigation/
// https://reactnavigation.org/docs/server-rendering#handling-404-or-other-status-codes
// https://reactnative.dev/docs/image
