import React from 'react'
import { Image } from 'react-native'

export default function F({width, height}) {
    return <Image 
    source={require('./../assets/icon.png')}
    resizeMode='contain'
    style={{width: width, height: height}}
    />
}
