import React from 'react'
import { Image } from 'react-native'

const imWidth = 1131
const imHeight = 2190
const r = imHeight / imWidth

export default function F({width}) {
    const height = width * r
    return <Image 
    source={require('./../assets/phone.png')}
    resizeMode='contain'
    style={{width: width, height: height}}
    />
}
